import { useState } from 'react';
import axios from 'axios';
import './../../style/css/formulaire.css'
import './../../style/css/formulaireConnexion.css'
import Cookies from 'js-cookie';
import InputText from '../inputs/inputText';
import BoutonEnvoyer from '../boutons/boutonEnvoyer'

function FormulaireConnexion({ onConnexionReussie }) {
    const [identifiant, setIdentifiant] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [erreur, setErreur] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!identifiant.trim()) {
            setErreur('Veuillez inscrire votre identifiant.');
            return;
        } else if (!motDePasse.trim()) {
            setErreur('Veuillez inscrire votre mot de passe.');
            return;
        }

        axios.post('/compte/connexion', {'mail':identifiant, 'mdp':motDePasse}).then((res) => {
            const { token, idCompte } = res.data;
            axios.defaults.headers.common['authorization'] = token;
            Cookies.set('token', token, { expires: 13 / 24 })
            onConnexionReussie();
        }).catch((error) => {
            setErreur('Utilisateur ou mot de passe incorrect. Veuillez vérifier vos identifiants.');
        })
    };

    return (
        <form className='formulaire formulaire-connexion' onSubmit={handleSubmit}>
            <InputText 
                id="identifiant" 
                value={identifiant}
                placeholder='Identifiant'
                onChange={(e) => setIdentifiant(e.target.value)} 
            />
            <InputText
                type="password" 
                id="motDePasse" 
                value={motDePasse} 
                placeholder='Mot de passe'
                onChange={(e) => setMotDePasse(e.target.value)} 
            />
            <BoutonEnvoyer texte='Se connecter' />
            {erreur && <p className='erreur'>{erreur}</p>}
        </form>
    );
}

export default FormulaireConnexion;