import H2 from '../titres/h2';
import './../../style/css/popup.css';
import './../../style/css/popupConnexion.css';
import ville from '../../medias/images/ville_abandonnee.webp'

function PopupConnexion({ fermerPopup, ouvrirPopupInscription, children }) {

    const handleOuvrirPopupInscription = () => {
        fermerPopup();
        ouvrirPopupInscription();
    };

    return (
        <div className='popup popup-connexion'>
            <div className='popup-conteneur'>
                <img src={ville} alt='Paysage désertique canyon'/>
                <div className='conteneur-interne'>
                    <span className='fermer' onClick={fermerPopup}>×</span>
                    <div className='popup-titre'>
                        <H2 titre="Connexion"></H2>
                    </div>
                    <div className='popup-contenu'>
                        {children}
                        <p className='lien-popup' onClick={handleOuvrirPopupInscription}>Pas de compte ? Créez-en un maintenant !</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopupConnexion;