import "./../../style/css/texteSection.css";
import axios from "axios";
import BoutonDecision from "../boutons/boutonDecision";
import BoutonGrand from "../boutons/boutonGrand";
import LanceDeDes from "./lanceDeDes";
import { useState, useEffect, useRef } from "react";
import Enigme from "./enigme";
import Combat from "./combat";
import PopupInfo from '../popup/popupInfo'
import H2 from '../titres/h2';
import BoutonRecommencer from '../boutons/boutonRecommencer';
import { ReactComponent as IconeCursor } from '../../medias/icones/cursor.svg'

const TexteSection = ({ idCompte, section, texteSection, typeAction, destinations, joueur, onSection, setJoueur, setInventaire}) => {
    const [mots, setMots] = useState([]);
    const [texteAAfficher, setTexteAAfficher] = useState("Chargement...");
    const [finAffichage, setFinAffichage] = useState(false);
    const [tableauTimeout, setTableauTimout] = useState([])
    const [texteSectionPropre, setTexteSectionPropre] = useState("")
    const [aptitudeAMaj, setaptitudeAMaj] = useState([])
    const [itemAMaj, setitemAMaj] = useState([])

    const popupRef = useRef();

    useEffect(() => {
        if (texteSection) {
            const wordsArray = texteSection.split(" ");
            if (wordsArray.length > 0) {
                setMots(wordsArray);
            }
        }
    }, [texteSection]);

    // Permet de faire remonter l'identifiant de section
    const handleSection = (idSection) => {
        setTexteAAfficher("");
        setFinAffichage(false);
        onSection(idSection);
    };

    const majAptitudePerso = (infosPoints) => {
        // Modification des infos du joueur
        if (joueur[infosPoints[0]] + Number(infosPoints[1]) < 0) {
            infosPoints[1] = -Number(joueur[infosPoints[0]])
        }
        const updatedJoueur = { ...joueur };
        updatedJoueur[infosPoints[0]] += Number(infosPoints[1]);
        setJoueur(updatedJoueur);
        let popupAptitude = '';
        switch (infosPoints[0].split("_")[1]){
            case 'habilete':
                popupAptitude = "popupHabilete"
                break;
            case 'endurance':
                popupAptitude = "popupEndurance"
                break;
            case 'chance':
                popupAptitude = "popupChance"
                break;
            case 'perception':
                popupAptitude = "popupPerception"
                break;
        }
        popupRef.current.afficherPopup(`${Number(infosPoints[1]) > 0 ? "+" : "-"}${Math.abs(Number(infosPoints[1]))} ${infosPoints[0].split("_")[1]}`, Number(infosPoints[1]) > 0 ? "green" : "red", popupAptitude)
        axios.post("/personnage/modifierNiveauAptitude", {
            id: idCompte,
            aptitude: infosPoints[0].split("_")[1],
            nombreDePoint: Math.abs(Number(infosPoints[1])),
            signe: Number(infosPoints[1]) > 0 ? true : false,
        }).then((res) => {
        }).catch((error) => {
            console.log(error);
        });
    }

    const majItemPerso = (item) => {
        // ajouter dans le front et la bdd
        axios.post("/personnage/ajouterItem", {
            idCompte: idCompte,
            idItem: Number(item)
        }).then((res) => {
            axios.post('/personnage/recupurerInventaire', {'idCompte':idCompte}).then((res) => {
                setInventaire(res.data);
                popupRef.current.afficherPopup(`L'item : ${res.data[res.data.length-1].nom}, a été ajouté à l'inventaire`, "green", "popupInventaire", 5)
            }).catch((error) => {
                console.error(error)
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    const skipAffichageText = () => {
        // la fonction enleve le texte affiché et le remplace par le texte propore sans les tag d'item ou d'aptitude
        // ensuite elle parcours les tableau d'item à ajouter et d'aptitude à mettre à jour et elle fait les maj
        if (tableauTimeout.length === 0) {
            return
        }
        for (let elem in tableauTimeout) {
            clearTimeout(tableauTimeout[elem])
        }
        setTexteAAfficher(texteSectionPropre)
        setFinAffichage(true);
        setTableauTimout([])
        for (let infosPoints of aptitudeAMaj) {
            majAptitudePerso(infosPoints)
        }
        setaptitudeAMaj([])

        for (let item of itemAMaj) {
            majItemPerso(item)
        }
        setitemAMaj([])
    }

    // useeffect qui se lance quand on les mots sont arrivés et rangés en tableau
    useEffect(() => {
        // Mise à jour de l'état du joueur après le rendu
        if (mots.length === 0) {
            return;
        }
        setTexteAAfficher("")
        setTexteSectionPropre("")
        mots.forEach((mot, index) => {
            if (mot.startsWith("|")) {
                if (joueur["id_section_actuelle"] != section) {
                    const infosPoints = mot.split("|")[1].split(":");
                    aptitudeAMaj.push(infosPoints)
                    setaptitudeAMaj(aptitudeAMaj)
                }
            } else if (mot.startsWith("*")) {
                if (joueur["id_section_actuelle"] != section) {
                    const item = Number(mot.split("*")[1])
                    itemAMaj.push(item)
                    setitemAMaj(itemAMaj)
                }
            } else {
                setTexteSectionPropre((prevTexte) => prevTexte + mot + " ")
            }
            tableauTimeout.push(setTimeout(() => {
                if (mot.startsWith("|")) {
                    // Modification des infos du joueur
                    if (aptitudeAMaj.length > 0) {
                        const infosPoints = aptitudeAMaj.shift()
                        setaptitudeAMaj(aptitudeAMaj)
                        majAptitudePerso(infosPoints)
                    }
                } else if (mot.startsWith("*")) {
                    // ajouter dans le front et la bdd
                    if (itemAMaj.length > 0) {
                        const item = itemAMaj.shift()
                        setitemAMaj(itemAMaj)
                        majItemPerso(item)
                    }
                } else {
                    setTexteAAfficher((prevTexte) => prevTexte + mot + " ");
                    if (index === mots.length - 1) {
                        setTableauTimout([])
                        setFinAffichage(true);
                    }
                }
            }, 50 * index))
            setTableauTimout(tableauTimeout)
        })
    }, [mots]);

    return (
        <section className="texteSection">
            <BoutonGrand texte="Revenir à l'accueil" lien="/" />
            <article id="basPage" onClick={ skipAffichageText }>
                <PopupInfo texte={""} couleur={"#cccccc"} ref={popupRef} />
                {finAffichage && (
                    <div className="action">
                        {typeAction === "choix simple" && joueur.niveau_endurance != 0 && (
                            // Pour chaque élément présent dans destinations, on ajoute un bouton sur la même ligne
                            <>
                                {Object.entries(destinations).map(
                                    ([texte, idSection], index) => (
                                        <BoutonDecision
                                            key={index}
                                            texte={texte}
                                            idSection={idSection}
                                            handleSection={handleSection}
                                        />
                                    )
                                )}
                            </>
                        )}

                        {/* Perception joueur > Perception des 2 dés pour réussir  Si victoire chemin 1 Sinon chemin 2 stockés dans destinations */}
                        {typeAction === "lancé de dés" && joueur.niveau_endurance != 0 && (
                            <LanceDeDes
                                destinations={destinations}
                                joueur={joueur}
                                handleSection={handleSection}
                            />
                        )}
                        {typeAction === "combat" && joueur.niveau_endurance != 0 && (
                            <>
                                <Combat
                                    joueur={joueur}
                                    setEnduranceJoueur={setJoueur}
                                    idSection={section}
                                    destinations={destinations}
                                    handleSection={handleSection}
                                />
                            </>
                        )}
                        {typeAction === "enigme" && joueur.niveau_endurance != 0 && (
                            <Enigme
                                destinations={destinations}
                                joueur={joueur}
                                onSection={handleSection}
                            />
                        )}
                        {typeAction === "fin de partie" && (
                            <>
                            <div className="finDeSection">
                                <H2 titre={`Vous avez ${destinations == "positive" ? "Gagné" : "Perdu"} !`}/>
                                <BoutonRecommencer texte={"Retour à la création de personnage"} lien={"/creation-personnage"} />
                            </div>
                            </>
                        )}
                    </div>
                )}
                <p className="texte">
                    { !finAffichage && <span title="Passer l'affichage du texte"><IconeCursor/>​</span>}
                    {texteAAfficher}
                </p>
            </article>
        </section>
    );
};

export default TexteSection;