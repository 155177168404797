import React, { useState } from 'react';
import axios from 'axios';
import BoutonDecision from '../boutons/boutonDecision';
import BoutonEnvoyer from '../boutons/boutonEnvoyer';

const Enigme = ({ destinations, joueur, onSection }) => {

    const [mot, setMot] = useState('');
    const [erreur, setErreur] = useState();
    const [message, setMessage] = useState();
    const [idSectionSuivante, setIdSectionSuivante] = useState();
    const [afficherForm, setAfficherForm] = useState(true);

    const handleSection = (idSection) => {
        onSection(idSection);
    };

    const verifierReponseJoueur = (event) => {
        event.preventDefault();

        if (!mot.trim()) {
            setErreur('Veuillez saisir votre proposition de réponse.');
            return;
        }

        axios.post('/enigme/levenshtein/', { 'idSection': joueur.id_section_actuelle, 'mot': mot.trim() })
            .then((result) => {
                let distance = result.data;
                setAfficherForm(false);

                if (distance <= mot.length / 3) {
                    setMessage("Bonne réponse");
                    setIdSectionSuivante(destinations["trouve"]);
                } else {
                    setMessage("Mauvaise réponse");
                    setIdSectionSuivante(destinations["pas trouve"]);
                }
            })
            .catch((errSection) => {
                console.error(errSection);
            });
    };

    return (
        <>
            {afficherForm && 
                <form className="formulaire" onSubmit={verifierReponseJoueur}>
                    <input style={{ textAlign: 'center' }} type="text" placeholder='Réponse' onChange={(e) => setMot(e.target.value)} />
                    <BoutonEnvoyer texte={"Soumettre"} />
                    {erreur && <p className='erreur'>{erreur}</p>}
                </form>
            }
            {message && <p>{message}</p>}
            {idSectionSuivante && <BoutonDecision texte={"Continuer"} idSection={idSectionSuivante} handleSection={handleSection} />}
        </>
    );
};

export default Enigme ;