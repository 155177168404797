// import './../../style/css/popupMort.css';
import H2 from "../titres/h2";
import './../../style/css/popupRecommencer.css';
import './../../style/css/popup.css';
import './../../style/css/popupMort.css';
import BoutonRecommencer from '../boutons/boutonRecommencer';

function PopupMort() {
    return (
        <div className='popup popup-recommencer'>
            <div className='popup-conteneur'>
                <div className='conteneur-interne'>
                    <div className='popup-titre'>
                        <H2 titre="Vous êtes mort"></H2>
                        <p>Vous êtes tombé à court de points d'endurance !</p>
                    </div>
                    <div className='popup-contenu'>
                        <BoutonRecommencer texte={"Retour à la création de personnage"} lien={"/creation-personnage"} />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PopupMort;