import './../../style/css/boutonDecision.css'


const BoutonDecision = ({id, texte, idSection, handleSection}) => {
    const handleClick = () => {
        handleSection(idSection);
    };

    return (
        <button id={id} className="bouton bouton-decision" onClick={handleClick}>{texte}
        <span></span><span></span><span></span><span></span>
        </button>
    )
}

export default BoutonDecision