import './../../style/css/h2.css'

const H2 = ({titre, titre_2}) => {
    return (
        <h2>
            {titre}
            {titre_2 && <span>{titre_2}</span>} 
        </h2>
    )
}

export default H2