import { Link } from 'react-router-dom';
import './../../style/css/bouton.css'
import './../../style/css/boutonGrand.css'

const BoutonGrand = ({texte, lien, action, disabled}) => {

    const handleClick = (event) => {
        if (action) {
            event.preventDefault();
            action();
        }
        if (disabled) {
            event.preventDefault();
        }
    };

    return (
        <>
            {lien && <Link to={lien} className={`bouton bouton-grand ${disabled ? 'disabled' : ''}`} disabled={disabled} onClick={handleClick}>
                {texte}
                <span></span><span></span><span></span><span></span>
            </Link>
            }
            {!lien && <span className='bouton bouton-grand' onClick={handleClick}>{texte}
                <span></span><span></span><span></span><span></span>
            </span>}
        </>
    )
}

export default BoutonGrand