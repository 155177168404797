import './../../style/css/champsDeModification.css'
import InputText from '../inputs/inputText';
import { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { ConnexionContext } from '../../utils/context/connexionContext';

const ChampsDeModification = ({route, type="text", texte, placeholder, popupRef, minLength}) => {
    const { estConnecte, idCompte, setEstConnecte, setIdCompte } = useContext(ConnexionContext);
    const [edition, setEdition] = useState(false)
    const [nomEdition, setNomEdition] = useState("Modifier")
    const [valeur, setValeur] = useState(texte)
    const inputRef = useRef(null);

    const changeEtatModification = (event) => {
        if (edition) {
            setEdition(false)
            setNomEdition("Modifier")
            if ((valeur == "") || (valeur == texte)) {
                return
            }
            if (valeur.length < minLength) {
                popupRef.current.afficherPopup(`Le mot de passe doit faire au moins 6 caractères.`, "red", undefined, 8)
                return
            }
            axios.post(route, {'idCompte': idCompte, 'valeur': valeur}).then((res) => {
                popupRef.current.afficherPopup(`Modification appliquée avec succès !`, "green", undefined, 8)
            }).catch((error) => {
                popupRef.current.afficherPopup(`${error.response.data}`, "red", undefined, 8)
            })
        } else if (!edition) {
            setEdition(true)
            setNomEdition("Valider")
            if (type == "password") {
                setValeur("")
            }
        }
    }

    useEffect(() => {
        if (edition && inputRef.current) {
            inputRef.current.focus();
        }
    }, [edition]);

    useEffect(() => {
        setValeur(texte);
    }, [texte]);

    return (
        <>
            <div className='inputModification'>
                <label>{placeholder}</label>
                {edition ? <InputText ref={inputRef} type={type} placeholder={placeholder} onChange={event => setValeur(event.target.value)} value={valeur}/> : <p>{ type == "text" ? valeur : "••••••"}</p>}
                <button className="bouton" onClick={ changeEtatModification }>{ nomEdition }
                    <span></span><span></span><span></span><span></span>
                </button>
            </div>
        </>
    )
}

export default ChampsDeModification