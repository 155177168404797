import '../../style/css/competenceInfo.css';

const CompetenceInfo = ({ nom, value, Icon }) => (
    <div className='competence-info'>
        <Icon className="icone-svg" />
        <p className="nom">{nom}</p>
        <p className="valeur">{value}</p>
    </div>
);
 
export default CompetenceInfo;