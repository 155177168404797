import { useState } from 'react';
import axios from 'axios';
import './../../style/css/formulaire.css'
import './../../style/css/formulaireInscription.css'
import Cookies from 'js-cookie';
import InputText from '../inputs/inputText';
import BoutonEnvoyer from '../boutons/boutonEnvoyer';

function FormulaireInscription({ onInscriptionReussie }) {
    const [identifiant, setIdentifiant] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [confirmationMotDePasse, setConfirmationMotDePasse] = useState('');
    const [erreur, setErreur] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!identifiant.trim()) {
            setErreur('Veuillez inscrire un identifiant.');
            return;
        } else if (motDePasse.trim().length < 6) {
            setErreur('Veuillez inscrire un mot de passe d\'au moins 6 caractères.');
            return;
        } else if (!confirmationMotDePasse.trim()) {
            setErreur('Veuillez confirmer votre mot de passe.');
            return;
        } else if (motDePasse !== confirmationMotDePasse) {
            setErreur('La confirmation du mot de passe ne correspond pas.');
            return;
        }

        axios.post('/compte/inscription', {'mail':identifiant, 'mdp':motDePasse}).then((res) => {
            const { token, idCompte } = res.data;
            axios.defaults.headers.common['authorization'] = token;
            Cookies.set('token', token, { expires: 13 / 24 })
            onInscriptionReussie();
        }).catch((error) => {
            if (error.response.status === 409) {
                setErreur('L\'identifiant existe déjà, veuillez en choisir un autre.')
            } else {
                setErreur('L\'inscription a échoué, veuillez réessayer.');
            }
        })
    };

    return (
        <form className='formulaire formulaire-inscription' onSubmit={handleSubmit}>
            <InputText 
                id="identifiant"
                value={identifiant}
                placeholder='Identifiant'
                onChange={(e) => setIdentifiant(e.target.value)} 
            />
            <InputText 
                type="password" 
                id="motDePasse" 
                value={motDePasse} 
                placeholder='Mot de passe'
                onChange={(e) => setMotDePasse(e.target.value)} 
            />
            <InputText 
                type="password" 
                id="confirmationMotDePasse" 
                value={confirmationMotDePasse} 
                placeholder='Confirmer le mot de passe'
                onChange={(e) => setConfirmationMotDePasse(e.target.value)} 
            />
            <BoutonEnvoyer texte="S'inscrire" />
            {erreur && <p className='erreur'>{erreur}</p>}
        </form>
    );
}

export default FormulaireInscription;