import {Routes, Route, useLocation} from 'react-router-dom'
import Accueil from './pages/accueil';
import CreationPersonnage from './pages/creationPersonnage';
import Section from './pages/section';
import Profil from './pages/profil';
import Test from './pages/test';
import { AnimatePresence } from 'framer-motion';

function App() {

    const location = useLocation()

    return (
        <>  
            <AnimatePresence initial={false} mode='wait'>
                <Routes location={location} key={location.pathname}>
                    <Route index element={<Accueil />} />
                    <Route path="/creation-personnage" element={<CreationPersonnage />} />
                    <Route path="/section" element={<Section />} />
                    <Route path="/profil" element={<Profil />} />
                    <Route path="/test" element={<Test />} />
                </Routes>
            </AnimatePresence>
        </>    
    )
}

export default App