import React, { useState, useContext, useEffect } from 'react';
import axios from "axios"
import PopupConnexion from '../composants/popup/popupConnexion';
import PopupInscription from '../composants/popup/popupInscription';
import PopupRecommencer from '../composants/popup/popupRecommencer';
import H1 from '../composants/titres/h1';
import BoutonGrand from '../composants/boutons/boutonGrand';
import BoutonRecommencer from '../composants/boutons/boutonRecommencer';
import FormulaireConnexion from '../composants/formulaires/formulaireConnexion';
import FormulaireInscription from '../composants/formulaires/formulaireInscription';
import '../style/css/accueil.css';
import { ConnexionContext } from '../utils/context/connexionContext';
import Cookies from 'js-cookie';
import TransitionSlide from '../transitionSlide';
import { ReactComponent as IconeProfil } from '../medias/icones/profil.svg'
import { useNavigate } from 'react-router-dom';

function Accueil() {
    const [popupConnexionOuverte, setPopupConnexionOuverte] = useState(false);
    const [popupInscriptionOuverte, setPopupInscriptionOuverte] = useState(false);
    const [popupRecommencerOuverte, setPopupRecommencerOuverte] = useState(false);
    const { estConnecte, idCompte, setEstConnecte, setIdCompte } = useContext(ConnexionContext);
    const [ aDejaUneGameEnCours, setADejaUneGameEnCours ] = useState(undefined)
    const navigate = useNavigate()
    
    useEffect(() => {
        if (estConnecte) {
            axios.post('/personnage/recupererJoueur', {'idCompte': idCompte}).then((res) => {
                setADejaUneGameEnCours(true)
            }).catch((error) => {
                setADejaUneGameEnCours(false)
            })
        }
    }, [idCompte]);

    const ouvrirPopupConnexion = () => {
        setPopupConnexionOuverte(true);
    };

    const fermerPopupConnexion = () => {
        setPopupConnexionOuverte(false);
    };

    const ouvrirPopupInscription = () => {
        setPopupInscriptionOuverte(true);
    };

    const fermerPopupInscription = () => {
        setPopupInscriptionOuverte(false);
    };

    const handleConnexionReussie = () => {
        setEstConnecte(true);
        fermerPopupConnexion();
    };

    const handleInscriptionReussie = () => {
        setEstConnecte(true);
        fermerPopupInscription();
    };

    const ouvrirPopupRecommencer = () => {
        setPopupRecommencerOuverte(true)
    }

    const fermerPopupRecommencer = () => {
        setPopupRecommencerOuverte(false)
    }

    const deconnexion = () => {
        Cookies.remove('token');
        setEstConnecte(false);
        setIdCompte(null);
    }

    return (
        <div className='accueil'>
            <section>
                <H1 titre="Les combattants de l'autoroute" titre_2="(Revisité)" ></H1>
                {
                    estConnecte ? (
                        <>
                            {aDejaUneGameEnCours !== undefined && (
                                <>
                                    <BoutonGrand texte="Commencer une nouvelle aventure" 
                                        lien={aDejaUneGameEnCours ? null : '/creation-personnage'}
                                        action={aDejaUneGameEnCours ? ouvrirPopupRecommencer : null} 
                                    />
                                    <BoutonGrand texte="Continuer" lien="/section" disabled={!aDejaUneGameEnCours} />
                                </>
                            )}
                            <IconeProfil onClick={() => {navigate("/profil")}}/>
                            <BoutonGrand texte="Se déconnecter" action={deconnexion} />
                        </>
                    ) : (
                        <>
                            <BoutonGrand texte="Se connecter" action={ouvrirPopupConnexion} />
                            <BoutonGrand texte="S'inscrire" action={ouvrirPopupInscription} />
                        </>
                    )
                }
            </section>

            {popupConnexionOuverte && (
                <PopupConnexion fermerPopup={fermerPopupConnexion} ouvrirPopupInscription={ouvrirPopupInscription}>
                    <FormulaireConnexion onConnexionReussie={handleConnexionReussie} />
                </PopupConnexion>
            )}

            {popupInscriptionOuverte && (
                <PopupInscription fermerPopup={fermerPopupInscription}>
                    <FormulaireInscription onInscriptionReussie={handleInscriptionReussie} />
                </PopupInscription>
            )}

            {popupRecommencerOuverte && (
                <PopupRecommencer fermerPopup={fermerPopupRecommencer}>
                    <BoutonRecommencer texte="Recommencer" lien="/creation-personnage"/>
                </PopupRecommencer>
            )}
        </div>
    );
}

export default TransitionSlide(Accueil);