import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TexteSection from '../composants/section/texteSection';
import { ConnexionContext } from '../utils/context/connexionContext';
import TransitionSlide from '../transitionSlide';
import "../style/css/informationsPersonnage.css";
import PopupInfo from '../composants/popup/popupInfo';
import CompetenceInfo from '../composants/competences/competenceInfo';
import "../style/css/section.css";
import PopupMort from '../composants/popup/popupMort';

import { ReactComponent as HabileteIcon } from '../medias/icones/habilete.svg';
import { ReactComponent as EnduranceIcon } from '../medias/icones/endurance.svg';
import { ReactComponent as ChanceIcon } from '../medias/icones/chance.svg';
import { ReactComponent as PerceptionIcon } from '../medias/icones/perception.svg';

import sacOuvert from '../medias/images/items/sac-ouvert.png';
import sacFerme from '../medias/images/items/sac-ferme.png';

import zipSound from '../medias/sons/zip.mp3';
import unzipSound from '../medias/sons/unzip.mp3';
import PopupInventaire from '../composants/popup/popupInventaire';

function Section() {
    const [section, setSection] = useState('{}');
    const [joueur, setJoueur] = useState({});
    const [leMecEstDead, setLeMecEstDead] = useState(false)
    
    const [inventaire, setInventaire] = useState([]);
    const [etatInventaire, setEtatInventaire] = useState(false);
    const [popupInventaireOuverte, setPopupInventaireOuverte] = useState(false);
    const { estConnecte, idCompte } = useContext(ConnexionContext);

	const zipAudioRef = useRef(null);
    const unzipAudioRef = useRef(null);

    const navigate = useNavigate()

    const ouvrirPopupInventaire = () => {
        setPopupInventaireOuverte(true);
    };

    const fermerPopupInventaire = () => {
        setPopupInventaireOuverte(false);
    };

    const handleInventaireClic = () => {
		setEtatInventaire(!etatInventaire);
        setPopupInventaireOuverte(!popupInventaireOuverte)
        if (etatInventaire) {
            zipAudioRef.current.play();
        } else {
            unzipAudioRef.current.play();
        }
    };

    const setJoueurTrigger = (joueur) => {
        setJoueur(joueur);
    };

    // Mis à jour des données lorsqu'un changement de section est demandé
    const handleRecuperationSection = (idSection) => {
        // MAJ de l'identifiant de la section du joueur
        axios.post('/personnage/modifierSectionActuelle', { 'idCompte': idCompte, 'idSection': idSection }).then((res) => {
            // Récupération des nouvelles données du joueur
            axios.post('/personnage/recupererJoueur', { 'idCompte': idCompte }).then((res) => {
                setJoueur(res.data);
            }).catch((error) => {
                console.error(error);
            });
        }).catch((error) => {
            console.error(error);
        });

        // Récupération de la nouvelle section
        axios.post('/section/recupSection', { 'idCompte': idCompte, 'idSection': idSection }).then((res) => {
            setSection(res.data);
        }).catch((error) => {
            console.error(error);
        });
    };

    // Récupération des données pour la première fois
    useEffect(() => {
        if (estConnecte) {
            axios.post('/personnage/recupererJoueur', { 'idCompte': idCompte }).then((resJoueur) => {
                setJoueur(resJoueur.data);
                axios.post('/section/recupSection', { 'idSection': resJoueur.data.id_section_actuelle }).then((resSection) => {
                    setSection(resSection.data);
                }).catch((errSection) => {
                    console.error(errSection);
                });
            }).catch((errJoueur) => {
                navigate("/creation-personnage")
                // console.error(errJoueur);
            });
        }
    }, [estConnecte, idCompte]);

    useEffect(() => {
        if (idCompte === undefined || idCompte === null) {
            return;
        }
        axios.post('/personnage/recupurerInventaire', { 'idCompte': idCompte }).then((res) => {
            setInventaire(res.data);
        }).catch((error) => {
            console.error(error);
        });
    }, [idCompte]);

    // Changement d'image à chaque nouvelle section
    useEffect(() => {
        if (section == '{}') {
            return
        }
        try {
            let images = require.context('../medias/images/sections', true);
            document.getElementsByClassName("section")[0].style.backgroundImage = `url(${images(`./${section.id}.jpg`)})`
        } catch {
            console.log("image pas trouvé")
            return
        }
    }, [section])

    // gestion de la mort
    useEffect(() => {
        if (joueur.niveau_endurance == undefined) {
            return
        }
        if (joueur.niveau_endurance <= 0) {
            setLeMecEstDead(true)
        }
    }, [joueur.niveau_endurance])

    const popupRef = useRef();

    function utiliserObjet(item, index) {
        // utiliser l'objet : modifier niveau aptitude
        if (joueur[item.type_effet] + Number(item.niveau_effet) >= 0) {
            const updatedJoueur = { ...joueur };
            updatedJoueur[item.type_effet] += Number(item.niveau_effet);
            setJoueur(updatedJoueur);

            // Choisir la className appropriée pour le toast
            let popupAptitude = '';
            switch (item.type_effet.split('_')[1]) {
                case 'habilete':
                    popupAptitude = "popupHabilete";
                    break;
                case 'endurance':
                    popupAptitude = "popupEndurance";
                    break;
                case 'chance':
                    popupAptitude = "popupChance";
                    break;
                case 'perception':
                    popupAptitude = "popupPerception";
                    break;
                default:
                    break;
            }

            axios.post("/personnage/modifierNiveauAptitude", {
                "id": idCompte,
                "aptitude": item.type_effet.split('_')[1],
                "nombreDePoint": Math.abs(Number(item.niveau_effet)),
                "signe": Number(item.niveau_effet) > 0 ? true : false,
            }).then((res) => {
            }).catch((error) => {
                console.log(error);
            });

            // supprimer l'objet
            axios.delete("/personnage/supprimerItem", {
                data: {
                    "idInventaire": item.id,
                },
            }).then((res) => {
                let inventaireTmp = inventaire.slice(0);
                inventaireTmp.splice(index, 1);
                setInventaire(inventaireTmp);
                popupRef.current.afficherPopup(`Vous utilisez ${item.nom} : ${item.niveau_effet > 0 ? "+" + item.niveau_effet : item.niveau_effet} ${capitalizeFirstLetter(item.type_effet.split('_')[1])}`, "green", popupAptitude, 5);
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    function capitalizeFirstLetter(mot) {
        if (mot === undefined) {
            return;
        }
        return mot.charAt(0).toUpperCase() + mot.slice(1);
    }

    return (
        <div className='section'>
            <PopupInfo texte={""} couleur={"#cccccc"} ref={popupRef} />
            <div className='competences-infos'>
                <CompetenceInfo nom="Endurance" value={joueur.niveau_endurance} Icon={EnduranceIcon} />
                <CompetenceInfo nom="Habileté" value={joueur.niveau_habilete} Icon={HabileteIcon} />
                <CompetenceInfo nom="Chance" value={joueur.niveau_chance} Icon={ChanceIcon} />
                <CompetenceInfo nom="Perception" value={joueur.niveau_perception} Icon={PerceptionIcon} />
            </div>
			{
				leMecEstDead && (
					<PopupMort />
				)
			}
            <TexteSection
                setInventaire={setInventaire}
                idCompte={idCompte}
                section={section.id}
                texteSection={section.texte}
                typeAction={section.type_action}
                destinations={section.destinations}
                joueur={joueur}
                setJoueur={setJoueurTrigger}
                onSection={handleRecuperationSection}
            />
            <div className="inventaire">
				<img className='sac' 
					src={etatInventaire ? sacOuvert : sacFerme} 
					alt={`Sac ${etatInventaire ? 'ouvert' : 'fermé'}`}
					onClick={handleInventaireClic}
				/>
				<audio ref={zipAudioRef} src={zipSound} />
            	<audio ref={unzipAudioRef} src={unzipSound} />
                {popupInventaireOuverte && (
                    <PopupInventaire fermerPopup={handleInventaireClic} ouvrirPopup={ouvrirPopupInventaire} inventaire={inventaire} utiliserObjet={utiliserObjet} nomPersonnage={joueur.nom}/>
                )}
            </div>
        </div>
    );
}

export default TransitionSlide(Section);