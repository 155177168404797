import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const ConnexionContext = createContext();

const ConnexionProvider = ({ children }) => {
    const [estConnecte, setEstConnecte] = useState(false);
    const [idCompte, setIdCompte] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const verifierConnexion = async () => {
            const token = Cookies.get('token');
            
            if (token) {
                axios.defaults.headers.common['authorization'] = token;
                await axios.get('/tokenValide').then(res => {
                    setEstConnecte(true);
                    setIdCompte(res.data["(👉ﾟヮﾟ)👉"]);
                }).catch(error => {
                    if (window.location.pathname !== '/') {
                        navigate('/')
                    }
                    setEstConnecte(false);
                });
            } else {
                if (window.location.pathname !== '/') {
                    navigate('/')
                }
                setEstConnecte(false);
            }
        };

        verifierConnexion();
    });

    return (
        <ConnexionContext.Provider value={{ estConnecte, idCompte, setEstConnecte, setIdCompte }}>
            {children}
        </ConnexionContext.Provider>
    );
};

export { ConnexionProvider, ConnexionContext };