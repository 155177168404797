import '../style/css/profil.css';
import TransitionSlide from '../transitionSlide';
import BoutonGrand from '../composants/boutons/boutonGrand';
import CompetenceInfo from '../composants/competences/competenceInfo';
import H2 from '../composants/titres/h2';
import ChampsDeModification from '../composants/champs/champsDeModification';
import { useState, useContext, useEffect, useRef } from 'react';
import { ConnexionContext } from '../utils/context/connexionContext';
import axios from 'axios';
import PopupInfo from '../composants/popup/popupInfo';

import { ReactComponent as HabileteIcon } from '../medias/icones/habilete.svg';
import { ReactComponent as EnduranceIcon } from '../medias/icones/endurance.svg';
import { ReactComponent as ChanceIcon } from '../medias/icones/chance.svg';
import { ReactComponent as PerceptionIcon } from '../medias/icones/perception.svg';

function Profil() {
    const popupRef = useRef();
    const { estConnecte, idCompte } = useContext(ConnexionContext);
    const [ aUnPersonnage, setAUnPersonnage ] = useState(false)
    const [ joueur, setJoueur ] = useState({})
    const [ compte, setInfoCompte ] = useState({})

    useEffect(() => {
        if (estConnecte) {
            axios.post('/personnage/recupererJoueur', {'idCompte': idCompte}).then((res) => {
                setJoueur(res.data);
                setAUnPersonnage(true)
            }).catch((error) => {
                setAUnPersonnage(false)
            })

            axios.post('/compte/infosCompte', {'idCompte': idCompte}).then((res) => {
                setInfoCompte(res.data)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [estConnecte, idCompte]);

    return (
        <div className='profil'>
            <PopupInfo texte={""} couleur={"#cccccc"} ref={popupRef} />
            <div>
            <BoutonGrand texte="Revenir à l'accueil" lien="/" />
            <h1>Profil</h1>
            </div>
            <div className='contenuProfil'>
                <div className='infoPersonnage'>
                    <H2 titre="Informations du personnage"/>
                    { aUnPersonnage ? <div>
                        <div className='input-infos-personnage'>
                            <ChampsDeModification popupRef={popupRef} placeholder={"Nom du personnage"} texte={joueur.nom} route={"/personnage/changerNom"}/>
                        </div>
                        <div className='competences-infos'>
                            <CompetenceInfo nom="Endurance" value={joueur.niveau_endurance} Icon={EnduranceIcon} />
                            <CompetenceInfo nom="Habileté" value={joueur.niveau_habilete} Icon={HabileteIcon} />
                            <CompetenceInfo nom="Chance" value={joueur.niveau_chance} Icon={ChanceIcon} />
                            <CompetenceInfo nom="Perception" value={joueur.niveau_perception} Icon={PerceptionIcon} />
                        </div>
                    </div> :
                    <>
                        <p className='pasDePerso'>Pas de personnage pour ce compte</p>
                        <BoutonGrand texte="Créer un personnage" lien="/creation-personnage" />
                    </>}
                </div>
                <div className='infoCompte'>
                    <H2 titre="Informations du compte"/>
                    <div>
                        <ChampsDeModification popupRef={popupRef} placeholder={"Nom du compte"} texte={compte.email} route={"/compte/changerEmail"}/>
                        <ChampsDeModification minLength={6} popupRef={popupRef} type={"password"} texte={""} placeholder={"Mot de passe"} route={"/compte/changerMdp"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransitionSlide(Profil);