import './../../style/css/h1.css'

const H1 = ({titre, titre_2}) => {
    return (
        <h1>
            {titre}
            {titre_2 && <span>{titre_2}</span>} 
        </h1>
    )
}

export default H1