import './../../style/css/bouton.css'
import './../../style/css/boutonEnvoyer.css'

const BoutonEnvoyer = ({texte, disabled}) => {
    return (
        <>
            <button type="submit" className={`bouton bouton-envoyer ${disabled ? 'disabled' : ''}`} disabled={disabled}>{texte}
                <span></span><span></span><span></span><span></span>
            </button>
        </>
    )
}

export default BoutonEnvoyer