import H2 from '../titres/h2';
import './../../style/css/popup.css';
import './../../style/css/popupInventaire.css';

function PopupInventaire({ fermerPopup, inventaire, utiliserObjet, nomPersonnage }) {

    let images = require.context('../../medias/images/items', true);
    let nbSlots = inventaire.length;
    
    const elements = [];
    let image
    inventaire.forEach((item, index) => {
        try {
            image = images(`./${item.id_item}.png`)
        } catch (error) {
            image = images(`./defaut.png`)
        }
        elements.push(
            <li
                className='slot'
                title={`Utiliser : ${item.type_effet.split('_')[1]} ${item.niveau_effet > 0 ? "+" + item.niveau_effet : item.niveau_effet}`}
                onClick={() => { utiliserObjet(item, index); }}
                key={index}
            >
                <img src={image} alt={`Item ${item.id_item}`} />
                <p className='utiliser-item'>Utiliser</p>
            </li>
        );
    });

    while (nbSlots < 17) {
        elements.push(
            <li
                className='slot slot-vide'
                title="Slot vide"
                key={nbSlots}
            >
            </li>
        );
        nbSlots++;
    }
    elements.push(
        <li
            className='slot slot-vide'
            title="Slot vide"
            key={nbSlots}
        >
        </li>
    );

    return (
        <div className='popup popup-inventaire'>
            <div className='popup-conteneur'>
                <div className='conteneur-interne'>
                    <span className='fermer' onClick={fermerPopup}>×</span>
                    <div className='popup-titre'>
                        <H2 titre={`Inventaire de ${nomPersonnage}`}></H2>
                    </div>
                    <div className='popup-contenu'>
                        <ul className="liste-inventaire">
                            {elements}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopupInventaire;