import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './style/css/defaut.css';
import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import axios from 'axios';
import App from './App'

import { ConnexionProvider } from './utils/context/connexionContext';


const host = "api.cayden.meatman.fr"
axios.defaults.baseURL = `https://${host}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  
  <React.StrictMode>
    <BrowserRouter>
      <ConnexionProvider >
          <Routes>
              <Route path="/*" element={<App />} />
          </Routes>
      </ConnexionProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();