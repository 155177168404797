import ReactDice from "react-dice-complete";
import axios from "axios";
import { useRef, useState, useEffect } from "react";
import BoutonDecision from "../boutons/boutonDecision";
import '../../style/css/combat.css';
import imageJoueur from "../../medias/images/joueur.png";
import imageGangster from '../../medias/images/gangster.png';
import BarreDeVie from "../barres/barreDeVie";

const Combat = ({
    joueur,
    setEnduranceJoueur,
    idSection,
    destinations,
    handleSection,
}) => {
    const reactDice = useRef(null);
    const [forceAttaque, setForceAttaque] = useState(0);
    const [aptitudeAdversaire, setAdversaire] = useState({});
    const [lancerDes, setLanceDes] = useState(true);
    const [animate, setAnimate] = useState(false)
    const [combatCommencer, setCombatCommencer] = useState(false);
    const [forceAttaqueAdversaire, setForceAttaqueAdversaire] = useState(0);
    const [combatFini, setCombatFini] = useState(false);
    const [sommePointsPerdu, setSommePointsPerdu] = useState(0);
    const [vieDebut, setVieDebut] = useState({ joueur: 0, ennemi: 0 });

    useEffect(() => {
        axios
            .post("/personnage/recupererEnnemi", { idSection: idSection })
            .then((res) => {
                setVieDebut(prevState => ({
                    joueur: joueur.niveau_endurance,
                    ennemi: res.data[0].niveau_endurance
                }));
                setAdversaire(res.data[0]); // Récupération d'un seul ennemi
            })
            .catch((error) => {
                console.error(error);
            });
    }, [idSection]);

    useEffect(() => {
        if (combatFini){
            miseAJourDuJoueur();
        }
    }, [combatFini]);
    
    const miseAJourDuJoueur = () => {
        axios.post("/personnage/modifierNiveauAptitude", {
            id: joueur.id_compte,
            aptitude: 'endurance',
            nombreDePoint: sommePointsPerdu,
            signe: false
        }).then((res) => {
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleRollDone = (total, values) => {
        if (!lancerDes) {
            setAnimate(false)

            setForceAttaque(total + joueur.niveau_habilete);
            let forceAttaqueEnnemi = adversaireForceAttaque();
            let forceAttaqueJoueur = total + joueur.niveau_habilete;
            setJoueur(forceAttaqueJoueur, forceAttaqueEnnemi);

            setTimeout(() => {
                setAnimate(true)
            }, 100);
        }
    };

    // Pour lancer tout les dés avec la lib
    const rollAll = () => {
        setLanceDes(false);
        reactDice.current?.rollAll();
    };

    const setJoueur = (forceAttaqueJoueur, forceAttaqueEnnemi) => {
        if (forceAttaqueJoueur < forceAttaqueEnnemi) {
            const updatedJoueur = { ...joueur };
            updatedJoueur.niveau_endurance = updatedJoueur.niveau_endurance - 2;
            setEnduranceJoueur(updatedJoueur);
            setSommePointsPerdu(sommePointsPerdu + 2);
            updatedJoueur.niveau_endurance <= 0 && setCombatFini(true);
        } else if (forceAttaqueJoueur > forceAttaqueEnnemi) {
            const updatedAdversaire = { ...aptitudeAdversaire };
            updatedAdversaire.niveau_endurance = updatedAdversaire.niveau_endurance - 2;
            setAdversaire(updatedAdversaire);
            updatedAdversaire.niveau_endurance <= 0 && setCombatFini(true);
        }
    };

    const adversaireForceAttaque = () => {
        let min = 1;
        let max = 6;
        let formule = Math.floor(Math.random() * (max - min + 1)) + min;
        let ForceAttaque = aptitudeAdversaire.niveau_habilete + formule;
        setTimeout(() => {
            setForceAttaqueAdversaire(ForceAttaque);
        }, 0);
        return ForceAttaque;
    };

    return (
        <div className="combat">

            {!combatCommencer ? (
                <div className="des-conteneur conteneur-centre">
                    <BoutonDecision id={"CommencerCombat"} texte={"Commencer le combat"} handleSection={() => setCombatCommencer(true)} />
                </div>
            ) : (
                <>
                    <div className="personnage">
                        <p className="nom">{joueur.nom}</p>
                        <img src={imageJoueur}/>
                        <p>Force d'attaque : <span className={animate ? 'force-attaque' : ''}>{forceAttaque}</span></p>
                        <BarreDeVie vieDebut={vieDebut.joueur} vieRestante={joueur.niveau_endurance} />
                    </div>

                    {combatFini ? (
                        <>
                            {joueur.niveau_endurance === 0 ? (
                                <BoutonDecision
                                    texte={"Défaite"}
                                    idSection={destinations.defaite}
                                    handleSection={handleSection}
                                />
                            ) : (
                                <BoutonDecision
                                    texte={"Victoire"}
                                    idSection={destinations.victoire}
                                    handleSection={handleSection}
                                />
                            )}
                        </>
                    ) : (
                        <div className="des-conteneur">
                            <p>Force d'attaque = habileté + Résultats dés</p>
                            <ReactDice
                                numDice={2}
                                ref={reactDice}
                                rollDone={handleRollDone}
                                rollTime={1}
                                defaultRoll={1}
                                faceColor={"#FFFFFF"}
                                dotColor={"#000000"}
                                outline={true}
                                disableIndividual={true}
                                callback={() => {}}
                            />
                            <BoutonDecision id={"lanceDes"} texte={"Lancer les dés"} handleSection={rollAll} />
                        </div>
                    )}

                    <div className="personnage">
                        <p className="nom">{aptitudeAdversaire.nom}</p>
                        <img 
                            src={require(`../../medias/images/${aptitudeAdversaire.nom.toLowerCase()}.png`)} 
                            alt={`Image de ${aptitudeAdversaire.nom}`} 
                        />
                        <p>Force d'attaque : <span className={animate ? 'force-attaque' : ''}>{forceAttaqueAdversaire}</span></p>
                        <BarreDeVie vieDebut={vieDebut.ennemi} vieRestante={aptitudeAdversaire.niveau_endurance} />
                    </div>
                </>
            )}
        </div>
    );
};

export default Combat;
