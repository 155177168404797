/* eslint-disable no-eval */
import BoutonDecision from "../boutons/boutonDecision";
import { useRef, useState } from "react";
import ReactDice from "react-dice-complete";

const LanceDeDes = ({ destinations, joueur, handleSection }) => {
    const reactDice = useRef(null);
    const [sommeDés, setSommeDés] = useState(0);
    const [afficherBoutonDecision, setAfficherBoutonDecision] = useState(false)

    const rollDone = (totalValue, values) => {
        setSommeDés(totalValue);
    };

    const rollAll =() => {
        document.getElementById('lanceDes').style.display = 'none';
        reactDice.current?.rollAll()

        setTimeout(() => {
            document.getElementById('somme').style.display = 'block';
            setAfficherBoutonDecision(true)
        },1500)
    };

    // Permet de faire remonter l'identifiant de section
    const handleSection2 = (idSection) => {
        handleSection(idSection);
    };

    // Extractions des signes et des aptitudes présent dans destinations
    const signes = [];
    const aptitudes = [];
    for (let destination in destinations) {
        const matches = destination.match(/^([<>]=?)([A-Za-z]+)/);
        if (matches) {
            signes.push(matches[1]);
            aptitudes.push(matches[2]);
        }
    }

    // Récupération du niveau de l'aptitude en jeu du joueur
    let niveauAptitude = joueur[`niveau_${aptitudes[0].toLowerCase()}`] ;

    // Déterminer quelle destination utiliser en fonction du niveau d'aptitude
    let idSection, texte;
    if (signes.length === 2) {
        if (eval(`${sommeDés} ${signes[0]} ${niveauAptitude}`)) {
            idSection = destinations[`${signes[0]}${aptitudes[0]}`];
            texte = "Niveau d'aptitude suffisant";
        } else if (eval(`${sommeDés} ${signes[1]} ${niveauAptitude}`)) {
            idSection = destinations[`${signes[1]}${aptitudes[0]}`];
            texte = "Niveau d'aptitude insuffisant";
        } else {
            console.log("Erreur de formatage en base de données pour la destination avec les lancés de dés")
        }
    } else {
        // Pop-up une erreur formatage des données de destination appartennant à la section
        console.error("Lancé de dés - Nombre d'issue différent de 2");
    }

    return (
        <>
            <article>
                <ReactDice
                    numDice={2}
                    ref={reactDice}
                    rollDone={rollDone}
                    rollTime={1}
                    defaultRoll={1}
                    faceColor={"#FFFFFF"}
                    dotColor={"#000000"}
                    outline={true}
                    disableIndividual={true}
                />
                <BoutonDecision id={"lanceDes"} texte={"Lancer les dés"} handleSection={rollAll} />
                <p id="somme">Somme des dés : {sommeDés}</p>
            </article>

            {afficherBoutonDecision && (
                <BoutonDecision
                    texte={texte}
                    idSection={idSection}
                    handleSection={handleSection2}
                />
            )}
        </>
    );
};

export default LanceDeDes;
