import React from 'react';
import '../../style/css/barreDeVie.css';

const BarreDeVie = ({ vieDebut, vieRestante }) => {
    let remplissageBarre = (vieRestante / vieDebut) * 100;
    let couleurBarre = '#FFF';

    if (remplissageBarre < 33) {
        couleurBarre = '#FF0000'; // rouge
    } else if (remplissageBarre < 66) {
        couleurBarre = '#FFA500'; // jaune / orange
    } else {
        couleurBarre = '#00FF00'; // vert
    }

    return (
        <div className="barreDeVie">
            <div 
                className="filler" 
                style={{
                    width: `${remplissageBarre}%`,
                    backgroundColor: couleurBarre
                }}
            ></div>
            <div className="labelContainer">
                <span className="label">{`${vieRestante} / ${vieDebut}`}</span>
            </div>
        </div>
    );
}

export default BarreDeVie;
