import H1 from "../composants/titres/h1";
import { useEffect } from "react";

function Test() {
    useEffect(() => {
        setTimeout(()=>{
            window.location.href = "https://bit.ly/3BlS71b"
        }, 200)
    }, []);
    return (
        <div>
            <H1>Je suis un test</H1>
        </div>
    );
}

export default Test;