import './../../style/css/popupInfo.css';
import { forwardRef, useImperativeHandle } from 'react';

const PopupInfo = forwardRef(( {texte, couleur}, ref) => {
    const publicRef = {
        afficherPopup: (texte, couleur, type, seconde=3) => {
            // Supprimer toute les classes du toast pour éviter qu'elles se chevauchent
            let popup = document.getElementById('popupInfo')
            popup.classList = '';
            popup.classList.add('popupInfo');
            popup.classList.add('show');
            popup.classList.add(type);
            popup.innerText = texte
            popup.style.backgroundColor = couleur
            setTimeout(() => {
                popup.classList.remove('show');
            }, seconde*1000);
        }
    };
    useImperativeHandle(ref, () => publicRef);

    return (
        <div id="popupInfo" className="popupInfo" style={{backgroundColor: couleur}}>
            {texte}
        </div>
    )
});

export default PopupInfo