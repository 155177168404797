import React, { useState, useContext, useRef } from 'react';
import axios from 'axios';
import { ConnexionContext } from '../../utils/context/connexionContext';
import '../../style/css/champCreationPersonnage.css';
import InputText from '../inputs/inputText';
import Competence from '../competences/competence';
import { useNavigate } from 'react-router-dom';

// Import des icônes SVG
import { ReactComponent as HabileteIcon } from '../../medias/icones/habilete.svg';
import { ReactComponent as EnduranceIcon } from '../../medias/icones/endurance.svg';
import { ReactComponent as ChanceIcon } from '../../medias/icones/chance.svg';
import { ReactComponent as PerceptionIcon } from '../../medias/icones/perception.svg';
import BoutonEnvoyer from '../boutons/boutonEnvoyer';
import PopupInfo from '../popup/popupInfo'
import BoutonGrand from '../boutons/boutonGrand';

const ChampsCreationPersonnage = () => {
    const [nom, setNom] = useState('');
    const [pointsDisponibles, setPointsDisponibles] = useState(20);
    const [competences, setCompetences] = useState({
        niveauHabilete: 0,
        niveauEndurance: 10,
        niveauChance: 0,
        niveauPerception: 0
    });

    const popupRef = useRef()

    const navigate = useNavigate();
    const { idCompte } = useContext(ConnexionContext);

    const handleIncrement = (competence) => {
        if (pointsDisponibles > 0) {
            setCompetences(prevCompetences => ({
                ...prevCompetences,
                [competence]: prevCompetences[competence] + 1
            }));
            setPointsDisponibles(pointsDisponibles - 1);
        }
    };

    const handleDecrement = (competence) => {
        if (competence === 'niveauEndurance') {
            if (competences[competence] > 10) {
                setCompetences(prevCompetences => ({
                    ...prevCompetences,
                    [competence]: prevCompetences[competence] - 1
                }));
                setPointsDisponibles(pointsDisponibles + 1);
            }
        } else {
            if (competences[competence] > 0) {
                setCompetences(prevCompetences => ({
                    ...prevCompetences,
                    [competence]: prevCompetences[competence] - 1
                }));
                setPointsDisponibles(pointsDisponibles + 1);
            }
        }
    };

    const repartirPointsAleatoirement = () => {
        let pointsRestants = pointsDisponibles;
        const nouvellesCompetences = { ...competences };
    
        // Liste des compétences
        const nomsCompetences = ['niveauEndurance', 'niveauHabilete', 'niveauChance', 'niveauPerception'];
    
        while (pointsRestants > 0) {
            // Choisir une compétence aléatoire
            const competenceChoisie = nomsCompetences[Math.floor(Math.random() * nomsCompetences.length)];
            // Ajouter un point à cette compétence
            nouvellesCompetences[competenceChoisie]++;
            // Réduire le nombre de points restants
            pointsRestants--;
        }
    
        setCompetences(nouvellesCompetences);
        setPointsDisponibles(0); // Tous les points ont été répartis
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            id: idCompte,
            nom: nom,
            ...competences
        };
        
        axios.post('/personnage/creerJoueur', data)
            .then((res) => {
                navigate('/section')
            })
            .catch((error) => {
                popupRef.current.afficherPopup("Un joueur existe déjà pour ce compte (une partie est déjà en cours).", "#ff4c00", 5)
            });
    };
        
    return (
        <>
            <PopupInfo texte={""} couleur={"#cccccc"} ref={popupRef} />
            <div className='champs-edition-personnage'>
                <form onSubmit={handleSubmit}>
                    <InputText id="nom" value={nom} placeholder="Nom" onChange={(e) => setNom(e.target.value)} />
                    <p className='points-disponibles'>Points de compétences disponibles: {pointsDisponibles}</p>
                    <BoutonGrand texte={"Répartir aléatoirement les points"} action={repartirPointsAleatoirement}/>

                    <div className='competences'>
                        <Competence
                            nom="Endurance"
                            value={competences.niveauEndurance}
                            handleDecrement={() => handleDecrement('niveauEndurance')}
                            handleIncrement={() => handleIncrement('niveauEndurance')}
                            Icon={EnduranceIcon}
                        />
                        <Competence
                            nom="Habileté"
                            value={competences.niveauHabilete}
                            handleDecrement={() => handleDecrement('niveauHabilete')}
                            handleIncrement={() => handleIncrement('niveauHabilete')}
                            Icon={HabileteIcon}
                        />
                        <Competence
                            nom="Chance"
                            value={competences.niveauChance}
                            handleDecrement={() => handleDecrement('niveauChance')}
                            handleIncrement={() => handleIncrement('niveauChance')}
                            Icon={ChanceIcon}
                        />
                        <Competence
                            nom="Perception"
                            value={competences.niveauPerception}
                            handleDecrement={() => handleDecrement('niveauPerception')}
                            handleIncrement={() => handleIncrement('niveauPerception')}
                            Icon={PerceptionIcon}
                        />
                    </div>
                    <BoutonEnvoyer texte='Valider' disabled={pointsDisponibles !== 0 || nom.trim() === ''} />
                </form>
            </div>
            <div id="popupReussi" className="popupReussi">
                Le joueur a été créé avec succès !
            </div>
            <div id="popupRate" className="popupRate">
                Il y a déjà un joueur associé à ce compte.
            </div>
        </>
    )
}

export default ChampsCreationPersonnage;