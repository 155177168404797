import './../../style/css/inputText.css';
import { forwardRef } from 'react';

const InputText = forwardRef(({ type, placeholder, onChange, value }, ref) => (
    <input
        ref={ref}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
    />
));

export default InputText;