import axios from 'axios';
import './../../style/css/bouton.css'
import './../../style/css/boutonRecommencer.css'
import { useContext } from "react"
import { ConnexionContext } from '../../utils/context/connexionContext';
import { Link, useNavigate } from 'react-router-dom';

const BoutonRecommencer = ({texte, lien, couleur}) => {
    const { idCompte } = useContext(ConnexionContext);
    const navigate = useNavigate()

    const handleClick = (event) => {
        event.preventDefault();
        // supprimer joueur
        axios.delete("/personnage/supprimerJoueur", {data:{"id":idCompte}}).then(res => {
            if (window.location.pathname !== lien) {
                navigate(lien)
            }
        }).catch(err => {
            console.log("Erreur : il y a eu une erreur lors de la suppression du compte.")
        })
    };

    return (
        <span className='bouton bouton-recommencer' style={{ backgroundColor: couleur }} onClick={handleClick}>
            <span></span><span></span><span></span><span></span>
            {lien && <Link to={lien}>{texte}</Link>}
            {!lien && <span>{texte}</span>}
        </span>
    )
}

export default BoutonRecommencer