import '../style/css/creationPersonnage.css';
import AutresChamps from '../composants/champs/ChampsCreationPersonnage';
import TransitionSlide from '../transitionSlide';

function CreationPersonnage() {
  return (
        <div className='sectionChamps'>
          <AutresChamps/>
        </div>
  );
}

export default TransitionSlide(CreationPersonnage);