import '../../style/css/competence.css';

const Competence = ({ nom, value, handleDecrement, handleIncrement, Icon }) => (
    <div className='competence'>
        <label className="nom">{nom}</label>
        <Icon className="icone-svg" />
        {handleDecrement && handleIncrement ? (
            <div className="conteneur-boutons">
                <button type="button" onClick={handleDecrement}>-</button>
                <span className="value">{value}</span>
                <button type="button" onClick={handleIncrement}>+</button>
            </div>
        ) : (
            <span className="value">{value}</span>
        )}
    </div>
);

export default Competence;