import H2 from '../titres/h2';
import './../../style/css/popup.css';
import './../../style/css/popupInscription.css';
import stationEssence from '../../medias/images/station_essence_abandonnee.webp';

function PopupInscription({ fermerPopup, children }) {
    return (
        <div className='popup popup-inscription'>
            <div className='popup-conteneur'>
                <img src={stationEssence} alt='Paysage désertique canyon'/>
                <div className='conteneur-interne'>
                    <span className='fermer' onClick={fermerPopup}>×</span>
                    <div className='popup-titre'>
                        <H2 titre="Inscription"></H2>
                    </div>
                    <div className='popup-contenu'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopupInscription;